<template>
  <ContentWrapper class="herobg">
    <div class="position-relative">
      <ClientOnly>
        <div
          class="herobg"
          :class="!mdAndUp ? 'bg__gradient_top' : ''"
          :style="{
            backgroundImage: `url(https://epd.directus.app/assets/${mdAndUp ? hero?.background_image : hero?.mobile_background}?format=webp&quality=80)`,
          }" />
      </ClientOnly>
      <v-container class="pa-0 inside pt-xl-16">
        <v-row align-content="start">
          <v-col cols="12" lg="8" class="text-center text-md-left pt-lg-3">
            <h1 class="mt-14 text-center text-md-left">
              {{ hero?.title }}
            </h1>
            <div class="claims mt-7 mb-9 mx-auto mx-md-0">
              <div
                v-for="claim in hero?.claims"
                :key="claim"
                class="font-weight-bold py-2 text-left">
                <v-icon class="text-primary">custom:check</v-icon>
                <span class="pl-4 claim">{{ claim }}</span>
              </div>
            </div>
            <ButtonPrimary
              width="100%"
              max-width="367px"
              :href="hero?.b2b_button_link"
              target="_blank">
              {{ hero?.b2b_button_label }}
            </ButtonPrimary>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </ContentWrapper>
</template>
<script setup lang="ts">
import { useDisplay } from 'vuetify'
import type { Hero } from '~/types/types'

const { mdAndUp } = useDisplay()

defineProps<{
  hero: Hero | null
}>()
</script>
<style scoped lang="scss">
.herobg {
  background-size: 100% 100%;
  aspect-ratio: 16/9;
  @media (min-width: 1800px) {
    min-height: 80vh;
  }
}
.inside {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: fit-content;
  top: 0;
  height: 100%;
}
.claims {
  width: fit-content;
}
@media (max-width: 599px) {
  h1 {
    font-size: 31px;
    line-height: 36.99px;
  }
}
@media (max-width: 420px) {
  .herobg {
    min-height: 840px;
    aspect-ratio: 6/9;
  }
}
@media (min-width: 421px) and (max-width: 959px) {
  .herobg {
    min-height: 840px;
    aspect-ratio: 6/12;
  }
}
@media (min-width: 960px) and (max-width: 1250px) {
  .herobg {
    min-height: 600px;
    aspect-ratio: 16/9;
  }
}
</style>
